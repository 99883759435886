<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
    >
      <template v-slot:PRODUCT_KEY>
        <div style="position: relative">
          <el-input v-model="formData.PRODUCT_KEY">
            <div slot="append">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                type="primary"
                @click="openRenewalDialog('PRODUCT_KEY')"
            >{{formData.PRODUCT_KEY === $t('accountPermissionManage.notHave') ? $t('accountPermissionManage.open') : $t('accountPermissionManage.renewal')}}</el-button>
            <el-button v-if="formData.PRODUCT_KEY !== $t('accountPermissionManage.notHave')" type="danger" @click="deactivate('PRODUCT_KEY')">{{$t('accountPermissionManage.deactivate')}}</el-button>
            </div>
          </el-input>
        </div>
      </template>
      <template v-slot:THERMAL_IMAGERY_FUNCTION_KEY>
        <div style="position: relative">
          <el-input v-model="formData.THERMAL_IMAGERY_FUNCTION_KEY">
            <div slot="append">
              <el-button
                  class="selectEquipmentButton"
                  v-if="cmd !== 'view'"
                  type="primary"
                  @click="openRenewalDialog('THERMAL_IMAGERY_FUNCTION_KEY')"
              >{{formData.THERMAL_IMAGERY_FUNCTION_KEY === $t('accountPermissionManage.notHave') ? $t('accountPermissionManage.open') : $t('accountPermissionManage.renewal')}}</el-button>
              <el-button v-if="formData.THERMAL_IMAGERY_FUNCTION_KEY !== $t('accountPermissionManage.notHave')" type="danger" @click="deactivate('THERMAL_IMAGERY_FUNCTION_KEY')">{{$t('accountPermissionManage.deactivate')}}</el-button>
            </div>
          </el-input>
        </div>
      </template>
      <template v-slot:TEAM_VIEWER_FUNCTION_KEY>
        <div style="position: relative">
          <el-input v-model="formData.TEAM_VIEWER_FUNCTION_KEY">
            <div slot="append">
              <el-button
                  class="selectEquipmentButton"
                  v-if="cmd !== 'view'"
                  type="primary"
                  @click="openRenewalDialog('TEAM_VIEWER_FUNCTION_KEY')"
              >{{formData.TEAM_VIEWER_FUNCTION_KEY === $t('accountPermissionManage.notHave') ? $t('accountPermissionManage.open') : $t('accountPermissionManage.renewal')}}</el-button>
              <el-button v-if="formData.TEAM_VIEWER_FUNCTION_KEY !== $t('accountPermissionManage.notHave')" type="danger" @click="deactivate('TEAM_VIEWER_FUNCTION_KEY')">{{$t('accountPermissionManage.deactivate')}}</el-button>
            </div>
          </el-input>
        </div>
      </template>
      <template v-slot:SENSOR_COLLECT_FUNCTION_KEY>
        <div style="position: relative">
          <el-input v-model="formData.SENSOR_COLLECT_FUNCTION_KEY">
            <div slot="append">
              <el-button
                  class="selectEquipmentButton"
                  v-if="cmd !== 'view'"
                  type="primary"
                  @click="openRenewalDialog('SENSOR_COLLECT_FUNCTION_KEY')"
              >{{formData.SENSOR_COLLECT_FUNCTION_KEY === $t('accountPermissionManage.notHave') ? $t('accountPermissionManage.open') : $t('accountPermissionManage.renewal')}}</el-button>
              <el-button v-if="formData.SENSOR_COLLECT_FUNCTION_KEY !== $t('accountPermissionManage.notHave')" type="danger" @click="deactivate('SENSOR_COLLECT_FUNCTION_KEY')">{{$t('accountPermissionManage.deactivate')}}</el-button>
            </div>
          </el-input>
        </div>
      </template>
      <template v-slot:ENDOSCOPE_FUNCTION_KEY>
        <div style="position: relative">
          <el-input v-model="formData.ENDOSCOPE_FUNCTION_KEY">
            <div slot="append">
              <el-button
                  class="selectEquipmentButton"
                  v-if="cmd !== 'view'"
                  type="primary"
                  @click="openRenewalDialog('ENDOSCOPE_FUNCTION_KEY')"
              >{{formData.ENDOSCOPE_FUNCTION_KEY === $t('accountPermissionManage.notHave') ? $t('accountPermissionManage.open') : $t('accountPermissionManage.renewal')}}</el-button>
              <el-button v-if="formData.ENDOSCOPE_FUNCTION_KEY !== $t('accountPermissionManage.notHave')" type="danger" @click="deactivate('ENDOSCOPE_FUNCTION_KEY')">{{$t('accountPermissionManage.deactivate')}}</el-button>
            </div>
          </el-input>
        </div>
      </template>
      <template v-slot:accountPermission>
        <div style="position: relative">
          <el-input v-model="formData.accountPermission" :readonly="true">
            <el-button
                class="selectEquipmentButton"
                v-if="cmd !== 'view'"
                slot="append"
                icon="el-icon-set"
                @click="openDialogTree"
            >{{$t('accountPermissionManage.setting')}}</el-button>
          </el-input>
        </div>
      </template>
      <template v-slot:bottom><div></div></template>
    </dialog-form-list>
    <renewal v-if="showRenewal" :tableRow="tableRow" :renewalType="renewalType" :is-open="isOpen" @closePage="closePage" />
    <dialog-tree
        :title="title"
        v-if="showDialogMultipleTree"
        height="400px"
        :isDefine="true"
        :defaultExpandedKeys="[1]"
        :defineConfirm="true"
        :defaultContent="defaultContent"
        @cancel="showDialogMultipleTree = false"
        @confirm="changeContactsVisible"
    >
      <template v-slot:default="{ data }">
        <div
            class="treeList"
            style="display: flex; justify-content: space-between; width: 100%"
        >
          <div>
            <el-checkbox
                class="canUseRadio"
                v-model="data.selected"
                :indeterminate="data.isIndeterminate"
                @change="changeSelected($event, data, 'selected')"
            />
            {{ data.name }}
          </div>
        </div>
      </template>
    </dialog-tree>
  </div>
</template>

<script>
import Renewal from './renewal'

export default {
  components: {
    Renewal,
  },
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      title: this.$t('accountPermissionManage.changeContactVisibleRange'),
      showDialogMultipleTree: false,
      defaultContent: [],
      renewalType: '',
      showRenewal:false,
      cmd: this.$store.state.cmd,
      formData: {},
      isOpen: false,
      config: [
        // lang:目标账号
        {
          label: this.$t('accountPermissionManage.targetAccount'),
          prop: 'targetAccount',
          hidden: false,
          tagProps: {
            readonly:'readonly'
          }
        },
        // lang:所属部门
        {
          tag: 'el-cascader',
          label: this.$t('accountPermissionManage.accountGroup'),
          prop: 'groupId',
          // rules: {
          //   noNull: true,
          // },
          tagProps: {
            options: [],
            props: {
              label: 'name',
              value: 'id',
            },
            disabled: true,
          },
          tagEvents:{

          }
        },
        // lang:产品密钥
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.productKey'),
          prop: 'PRODUCT_KEY',
        },
        // lang:热成像
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.thermalImagingFunction'),
          prop: 'THERMAL_IMAGERY_FUNCTION_KEY',
        },
        // lang:远程控制
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.remoteControlFunction'),
          prop: 'TEAM_VIEWER_FUNCTION_KEY',
        },
        // lang:传感器采集
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.sensorAcquisitionFunction'),
          prop: 'SENSOR_COLLECT_FUNCTION_KEY',
        },
        // lang:内窥镜
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.endoscopicFunction'),
          prop: 'ENDOSCOPE_FUNCTION_KEY',
        },
        // lang:联系人可见范围
        {
          tag: 'slot-content',
          label: this.$t('accountPermissionManage.contactVisibleRange'),
          prop: 'accountPermission',
        },
        // lang:联系人可见范围
        {
          tag: 'el-select',
          label: this.$t('accountPermissionManage.addContact'),
          prop: 'addContact',
          tagProps: {
            options: [
              {
                label: this.$t('accountPermissionManage.allow'),
                value: 'YES',
              },
              {
                label: this.$t('accountPermissionManage.notAllow'),
                value: 'NO',
              },
            ],
          },
          tagEvents:{
            change(evt) {
              console.log('evt', evt)
              self.$ajax({
                url: '/' + self.$util.getLocalStorage('apiprefix') + self.$api.changeContactsAddAble,
                data: {
                  accountUid: self.tableRow.userAccountUid,
                  canAddContacts: evt,
                },
              })
            }
          }
        },
      ],
    }
  },
  mounted() {
    this.setDefaultValue()
    this.userAccountGroupFindTree()
  },
  methods: {
    deactivate(type) {
      this.$element
          .showConfirm({
            content: this.$t('accountPermissionManage.deactivateConfirm'),
          })
          .then(() => {
            this.$ajax({
              url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.disabledAccount,
              data: {
                uid: this.tableRow.userAccountUid,
                type
              },
            }).then(() => {
              // lang:删除成功
              this.$element.showMsg(this.$t('base.deactivateSuccess'), 'success')
              this.formData[type] = this.$t('accountPermissionManage.notHave')
            })
          })
    },
    closePage(evt) {
      this.showRenewal = false
      if(evt === 'add') {
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findAccountPermission,
          data: {
            uid: this.tableRow.userAccountUid,
          },
        }).then((data) => {
          let pk = data.secretKeyDistributionList.filter(item => item.type === 'PRODUCT_KEY')
          if(pk.length !== 0) {
            this.formData.PRODUCT_KEY = this.$util.format(pk[0].expirationDate)
          } else {
            this.formData.PRODUCT_KEY = this.$t('accountPermissionManage.notHave')
          }
          let ti = data.secretKeyDistributionList.filter(item => item.type === 'THERMAL_IMAGERY_FUNCTION_KEY')
          if(ti.length !== 0) {
            this.formData.THERMAL_IMAGERY_FUNCTION_KEY = this.$util.format(ti[0].expirationDate)
          } else {
            this.formData.THERMAL_IMAGERY_FUNCTION_KEY = this.$t('accountPermissionManage.notHave')
          }
          let tv = data.secretKeyDistributionList.filter(item => item.type === 'TEAM_VIEWER_FUNCTION_KEY')
          if(tv.length !== 0) {
            this.formData.TEAM_VIEWER_FUNCTION_KEY = this.$util.format(tv[0].expirationDate)
          } else {
            this.formData.TEAM_VIEWER_FUNCTION_KEY = this.$t('accountPermissionManage.notHave')
          }
          let sc = data.secretKeyDistributionList.filter(item => item.type === 'SENSOR_COLLECT_FUNCTION_KEY')
          if(sc.length !== 0) {
            this.formData.SENSOR_COLLECT_FUNCTION_KEY = this.$util.format(sc[0].expirationDate)
          } else {
            this.formData.SENSOR_COLLECT_FUNCTION_KEY = this.$t('accountPermissionManage.notHave')
          }
          let e = data.secretKeyDistributionList.filter(item => item.type === 'ENDOSCOPE_FUNCTION_KEY')
          if(e.length !== 0) {
            this.formData.ENDOSCOPE_FUNCTION_KEY = this.$util.format(e[0].expirationDate)
          } else {
            this.formData.ENDOSCOPE_FUNCTION_KEY = this.$t('accountPermissionManage.notHave')
          }
        })
      }
    },
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          companyId: this.$store.getters.company.id,
          scope: 'COMPANY',
        },
      }).then((data) => {
        this.config[1].tagProps.options = data
      })
    },
    // 弹出续期窗口
    openRenewalDialog(type) {
      this.showRenewal = true
      this.renewalType = type
      this.isOpen = this.formData[type] === this.$t('accountPermissionManage.notHave')
    },
    openDialogTree() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findCompanyTreeContacts,
        data: {
        },
      }).then((data) => {
        console.log(data)
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findAccountPermission,
          data: {
            uid: this.tableRow.userAccountUid,
          },
        }).then((res) => {
          data.map(item => {
            if(res.accountPermission.visibleType === 'ALL') {
              item.selected = true
              if(item.children && item.children.length !== 0) {
                item.children.map(item1 => {
                  item1.selected = true
                })
              }
            } else if(res.accountPermission.visibleType === 'PART'){
              if(res.accountPermission.groupsSet !== null) {
                res.accountPermission.groupsSet.forEach(item2 => {
                  if(item2.uid === item.uid) {
                    item.selected = true
                    if(item.children && item.children.length !== 0) {
                      item.children.map(item3 => {
                        item3.selected = true
                      })
                    }
                  }
                })
              }
              if(res.accountPermission.accountsSet !== null) {
                res.accountPermission.accountsSet.forEach(item4 => {
                  if(item.children && item.children.length !== 0) {
                    item.children.map(item5 => {
                      if(item4.uid === item5.uid) {
                        item5.selected = true
                      }
                    })
                  }
                })
                let flag = false
                item.children.forEach(item6 => {
                  if(! item6.selected) {
                    flag = true
                  }
                })
                if(flag) {
                  item.isIndeterminate = true
                  item.selected = false
                } else {
                  item.isIndeterminate = false
                  item.selected = true
                }
              }
            }
          })
          data.map(item => {
            if(!item.selected) {
              item.selected = false
            }
            if(item.children && item.children.length !== 0) {
              item.children.map(item1 => {
                if(!item1.selected) {
                  item1.selected = false
                }
              })
            }
          })
          console.log('after', data)
          this.defaultContent = data
          this.showDialogMultipleTree = true
        })
      })
    },
    changeSelected(evt, data, key) {
      console.log(evt, data, key)
      console.log(this.defaultContent)
      if(data.nodeType === 'GROUP') {
        this.defaultContent.map(item => {
          if(item.uid === data.uid) {
            item.selected = evt
            if(item.children && item.children.length !== 0) {
              item.children.map(item1 => {
                item1.selected = evt
              })
            }
          }
        })
      }
      if(data.nodeType === 'ACCOUNT') {
        this.defaultContent.map(item => {
          if(item.children && item.children.length !== 0) {
            item.children.map(item1 => {
              if(item1.uid === data.uid) {
                item1.selected = evt
              }
            })
          }
        })
      }
      let flag = false
      this.defaultContent.map(item => {
        item.children.forEach(item2 => {
          if (!item2.selected) {
            flag = true
          }
        })
        if (flag) {
          item.isIndeterminate = true
          item.selected = false
        } else {
          item.isIndeterminate = false
          item.selected = true
        }
        if(!evt && data.nodeType === 'GROUP') {
          item.isIndeterminate = false
        }
      })
    },

    changeContactsVisible() {
      let formData = {
        accountUid: this.tableRow.userAccountUid,
        visibleType: 'ALL',
        groupsUidList: [],
        accountsUidList: [],
      }

      this.defaultContent.forEach(item => {
        if(! item.selected) {
          formData.visibleType = 'PART'
          if(item.children && item.children.length !== 0) {
            item.children.forEach(item1 => {
              if(item1.selected) {
                formData.accountsUidList.push(item1.uid)
              }
            })
          }
        } else {
          formData.groupsUidList.push(item.uid)
        }
      })
      if(formData.groupsUidList.length === 0) {
        formData.groupsUidList = null
      }
      if(formData.accountsUidList.length === 0) {
        formData.accountsUidList = null
      }
      if(formData.visibleType === 'ALL') {
        formData.groupsUidList = null
        formData.accountsUidList = null
      }
      console.log(formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.changeContactsVisible,
        data: formData,
      }).then((data) => {
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findAccountPermission,
          data: {
            uid: this.tableRow.userAccountUid,
          },
        }).then((data) => {
          let aaa = ''
          if(data.accountPermission.visibleType === 'PART') {
            if(data.accountPermission.groupsSet !== null) {
              data.accountPermission.groupsSet.forEach(item => aaa += item.name + '、')
            }
            if(data.accountPermission.accountsSet !== null) {
              data.accountPermission.accountsSet.forEach(item => aaa += item.name + '、')
            }
          } else if(data.accountPermission.visibleType === 'ALL') {
            aaa += this.$t('accountPermissionManage.allPermission') + '、'
          }
          this.formData.accountPermission = aaa.substr(0, aaa.length - 1)
          this.showDialogMultipleTree = false
        })
      })
    },


    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          this.formData.targetAccount = this.tableRow.name + ' ' + this.tableRow.accountId
          this.formData.groupId = this.tableRow.groupId
          let pk = this.tableRow.secretKeyDistributionList.filter(item => item.type === 'PRODUCT_KEY')
          if(pk.length !== 0) {
            this.formData.PRODUCT_KEY = this.$util.format(pk[0].expirationDate)
          } else {
            this.formData.PRODUCT_KEY = this.$t('accountPermissionManage.notHave')
          }
          let ti = this.tableRow.secretKeyDistributionList.filter(item => item.type === 'THERMAL_IMAGERY_FUNCTION_KEY')
          if(ti.length !== 0) {
            this.formData.THERMAL_IMAGERY_FUNCTION_KEY = this.$util.format(ti[0].expirationDate)
          } else {
            this.formData.THERMAL_IMAGERY_FUNCTION_KEY = this.$t('accountPermissionManage.notHave')
          }
          let tv = this.tableRow.secretKeyDistributionList.filter(item => item.type === 'TEAM_VIEWER_FUNCTION_KEY')
          if(tv.length !== 0) {
            this.formData.TEAM_VIEWER_FUNCTION_KEY = this.$util.format(tv[0].expirationDate)
          } else {
            this.formData.TEAM_VIEWER_FUNCTION_KEY = this.$t('accountPermissionManage.notHave')
          }
          let sc = this.tableRow.secretKeyDistributionList.filter(item => item.type === 'SENSOR_COLLECT_FUNCTION_KEY')
          if(sc.length !== 0) {
            this.formData.SENSOR_COLLECT_FUNCTION_KEY = this.$util.format(sc[0].expirationDate)
          } else {
            this.formData.SENSOR_COLLECT_FUNCTION_KEY = this.$t('accountPermissionManage.notHave')
          }
          let e = this.tableRow.secretKeyDistributionList.filter(item => item.type === 'ENDOSCOPE_FUNCTION_KEY')
          if(e.length !== 0) {
            this.formData.ENDOSCOPE_FUNCTION_KEY = this.$util.format(e[0].expirationDate)
          } else {
            this.formData.ENDOSCOPE_FUNCTION_KEY = this.$t('accountPermissionManage.notHave')
          }

          let aaa = ''
          if(this.tableRow.accountPermission.visibleType === 'PART') {
            if(this.tableRow.accountPermission.groupsSet !== null) {
              this.tableRow.accountPermission.groupsSet.forEach(item => aaa += item.name + '、')
            }
            if(this.tableRow.accountPermission.accountsSet !== null) {
              this.tableRow.accountPermission.accountsSet.forEach(item => aaa += item.name + '、')
            }
          } else if(this.tableRow.accountPermission.visibleType === 'ALL') {
            aaa += this.$t('accountPermissionManage.allPermission') + '、'
          }
          this.formData.accountPermission = aaa.substr(0, aaa.length - 1)
          this.formData.addContact = this.tableRow.canAddOutsideUserAccount
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel1')
    },
  },
}
</script>
